<template>
  <div class="text-center">
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar Vinculação"
      icon="EditIcon"
      size="20"
      @click="$router.push(`/update-user-workspace/${item.id}/${item.access.workspace_id}`)"
    />
    <!-- <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Excluir Vinculação"
      icon="TrashIcon"
      size="15"
      class="ml-1"
      @click="deleteUser"
    /> -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  name: 'actions-users-workspace',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },

};
</script>
